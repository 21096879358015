import styled from 'styled-components';

interface IContent {
  margin?: string;
}

export const Content = styled.div<IContent>`
  width: 100%;

  margin: ${(props) => (props.margin ? `${props.margin}` : '10px 0')};

  @media (max-width: 500px) {
    margin: 10px 0 0 0 !important;
  }

  label {
    display: block;
    margin-bottom: 5px;
    margin-left: 10px;
  }

  select {
    height: 42px;
    border: 1px solid #e6e6e6;
    background: none;
    width: 100%;
    padding: 0 18px;
    border-radius: 20px;

    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px #48A3F2;
        border: 1px solid #48A3F2;
    }
  }
`;
