import { Container } from './styles';
import { Simulator } from 'components/Simulator';

export const SimulationMinimal = () => {
  return (
    <Container >
      <Simulator />      
    </Container>
  );
};
