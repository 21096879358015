/* eslint-disable prettier/prettier */
import styled from 'styled-components';

interface ContainerProps {
  direction?: 'row' | 'column';
  justifyContent?:
  | string
  | 'space-between'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'baseline';
  alignItems?:
  | string
  | 'space-between'
  | 'center'
  | 'flex-end'
  | 'flex-start'
  | 'baseline';
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  flexBasis?: string;
  flexWrap?: string;
  noWrap?: Boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};

  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : '100%')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  flex-basis: ${(props) => (props.flexBasis ? props.flexBasis : 'auto')};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'auto')};

  @media (max-width: 500px) {
    flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')};
  }
`;
