import { IWizzardSteps } from './types';
import { Container, StepsIndicator } from './styles';

export const WizzardSteps = ({ steps, step }: IWizzardSteps) => {
  const indicators = () => {
    const indicator = [];

    let count = 1;
    for (count; count <= steps; count += 1) {
      indicator.push(<StepsIndicator key={count} done={count <= step}>{count}</StepsIndicator>);
    }
    return indicator;
  };

  return <Container>{indicators()}</Container>;
};
