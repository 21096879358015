import { Container } from './styles';
import { IInput } from './types';

export const Input = ({
  id,
  label,
  isError,
  errorMessage,
  isFullWidth,
  margin,
  ...rest
}: IInput) => {
  return (
    <Container isFullWidth={isFullWidth} isError={isError} margin={margin}>
      <label htmlFor={id}>{label}</label>
      <input id={id} {...rest} />
      {isError && (
        <small>
          <span>{errorMessage}</span>
        </small>
      )}
    </Container>
  );
};
