import { Switch, Route } from 'react-router-dom';

import { Simulation } from 'pages/Simulation';
import { SimulationMinimal } from 'pages/SimulationMinimal';
import { Questions } from 'pages/Questions';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/:promoter" component={Simulation} />
      <Route exact path="/:promoter/minimal" component={SimulationMinimal} />
      <Route exact path="/:promoter/duvidas" component={Questions} />
      <Route exact path="/:promoter/idc/:indication" component={Simulation} />
      <Route exact path="/:promoter/minimal/idc/:indication" component={SimulationMinimal} />
    </Switch>
  );
};
