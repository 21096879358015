import { ThemeProvider } from 'styled-components';

import { ITheme } from './types';
import { useCustomerTheme } from './useCustomerTheme';
import { GlobalStyle } from './GlobalStyle';

export const Theme = ({ children }: ITheme) => {
  const customerTheme = useCustomerTheme();
  const GlobalStyleTheme = GlobalStyle();

  return (
    <ThemeProvider theme={customerTheme}>
      <GlobalStyleTheme />
      {children}
    </ThemeProvider>
  );
};
