import { Content } from './styles';
import { IForm } from './types';

export const Form = ({ onSubmit, children, ...rest }: IForm) => {
  return (
    <Content onSubmit={onSubmit} {...rest}>
      {children}
    </Content>
  );
};
