import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useMutation } from 'react-query';

import api from 'services/api';
import Swal from 'sweetalert2';

import {
  IUseCreateSimulationResult,
  IUseCreateSimulation,
  IUseCreateDigitation,
  IUseCreateDigitationResult,
} from './types';

export const useCreateSimulation = () => {

  
  const { promoter } = usePromoterContext();

  const create = useMutation(async (data: IUseCreateSimulation) => {
    const response: IUseCreateSimulationResult = await api.post(
      '/v1/finance/simulation',
      data
    );
    return response;
  }, {
    onError : (err : any) => {

      let error = 'Ocorreu um erro inesperado';

      if(err.response && err.response.data && err.response.data.message){
        error = err.response.data.message;
        
        if(
          error === "ERRO BANCO SAFRA: Trabalhador informado não possui contas de FGTS." ||
          error === "ERRO BANCO SAFRA: Instituição Fiduciária não possui autorização do Trabalhador para Operação Fiduciária." ||
          error === "ERRO BANCO SAFRA: Trabalhador não possui adesão ao saque aniversário vigente na data corrente." ||
          error === "Trabalhador informado não possui contas de FGTS." ||
          error === "Instituição Fiduciária não possui autorização do Trabalhador para Operação Fiduciária." ||
          error === "Trabalhador não possui adesão ao saque aniversário vigente na data corrente." ||
          error === "Erro: Cliente precisa liberar o saque aniversário no aplicativo do FGTS" ||
          error === "Erro: BancoC6 não foi liberado no aplicativo da Caixa, é preciso realizar a liberação"
        ){
          return this;
        }

      }

      Swal.fire({
        title: 'Ocorreu um erro',
        text: error,
        icon: 'error',
        confirmButtonText: 'Ok',
        footer: `<a href="https://api.whatsapp.com/send?phone=55${promoter?.promoterStyles.contactMovelPhone}" target="_blank">Falar com atendente!</a>`
      });
    }
  });
  return create;
};

export const useCreateDigitation = () => {
  
  const { promoter } = usePromoterContext();

  const create = useMutation(async (data: IUseCreateDigitation) => {
    const response: IUseCreateDigitationResult = await api.post(
      `/v1/digitation/proposal/${data.id}/${data.idSimulation}`,
      data
    );
    return response;
  }, {
    onError : (err : any) => {

      let error = 'Ocorreu um erro inesperado';

      if(err.response && err.response.data && err.response.data.message){
        error = err.response.data.message;
      }

      Swal.fire({
        title: 'Proposta não finalizada',
        text: error,
        icon: 'error',
        confirmButtonText: 'Ok',
        footer: `<a href="https://api.whatsapp.com/send?phone=55${promoter?.promoterStyles.contactMovelPhone}" target="_blank">Falar com atendente!</a>`
      })
    }
  });
  return create;
};
