import { Content } from './styles';
import { ISelect } from './types';

const Select = ({ id, label, options, margin, ...rest }: ISelect) => {
  return (
    <Content margin={margin}>
      <label htmlFor={id}>{label}</label>
      <select id={id} {...rest}>
        <option value="" disabled>
          Selecione...
        </option>
        {options &&
          options.map((option) =>
            typeof option === 'string' ? (
              <option key={option} value={option}>
                {option}
              </option>
            ) : (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          )}
      </select>
    </Content>
  );
};

export default Select;
