import { Container } from './styles';
import { ICheckbox } from './types';

export const Checkbox = ({ id, label, margin, ...rest }: ICheckbox) => {
  return (
    <Container margin={margin}>
      <label htmlFor={id}>
        <input id={id} type="checkbox" {...rest} />
        <span>{label}</span>
      </label>
    </Container>
  );
};
