import { Container } from './styles';
import { ITypography } from './types';

export const Typography = ({
  children,
  color,
  size,
  fontWeight,
}: ITypography) => {
  return (
    <Container color={color} size={size} fontWeight={fontWeight}>
      {children}
    </Container>
  );
};
