import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
// import ReactHtmlParser from 'react-html-parser';

import { Container } from 'components/Container';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Routes } from 'routes';

import { useGetPromoter } from 'hooks/promoter/usePromoterQuery';
import { usePromoterContext } from 'context/promoter/usePromoterContext';

export const ValidateURL = () => {
  const [newPathname, setNewPathname] = useState('');
  const [indication, setIndication] = useState('');
  const [match, setMatch] = useState<RegExpMatchArray | null>(null);
  const [onlyScript, setOnlyScript] = useState('');
  const { pathname } = useLocation();
  const { setPromoter } = usePromoterContext();
  const { data, refetch } = useGetPromoter(newPathname, indication);

  const pathArray = pathname.split('/');
  
  useEffect(() => {
    if (pathArray[1]) {
      setNewPathname(`/${pathArray[1]}`);
      if(pathArray[3]){
        setIndication(pathArray[3]);
      }
      if(newPathname && !data) refetch()
    }    

    if (data) {
      setPromoter(data);
      if(data.promoterStyles.scripts) 
      {
        setMatch(data.promoterStyles.scripts.match(/src\s*=\s*"(.+?)"/g));
        setOnlyScript(data.promoterStyles.scripts.replace('<script>', '').replace('</script>',''));
      }
    };

    TagManager.initialize({
      gtmId : data?.promoterStyles.tagManager ?? ''
    });
    
    ReactPixel.init( data?.promoterStyles.pixel ?? '');
    ReactPixel.pageView();

  }, [data, pathname, newPathname]);

  if (pathname === '/') {
    return <h1>URL Invalida</h1>;
  }

  return (
    <>
      {pathArray[2] === 'minimal' ? (
        <Routes />
      ) : (
        <Container>
          <Header />
          <Routes />
          <Footer />
        </Container>
      )}
      {/* {ReactHtmlParser(data.promoterStyles.scripts)} */}
      <Helmet>
        {(match && match?.length > 0) && (
          match.map((src, index) => (
            <script key={index} src={src.replace('src="', '').replace('"', '')}></script>
          ))
        )}
        {(!match && onlyScript !== '') && (
            <script>{onlyScript}</script>
        )}
      </Helmet>
    </>
  );
};
