import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 9px solid transparent;
  border-right: 7px solid ${(props) => props.theme.colors.primary};
  border-bottom: 7px solid ${(props) => props.theme.colors.primary};
  border-left: 7px solid ${(props) => props.theme.colors.primary};
  background: transparent;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
`;
