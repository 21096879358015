/* eslint-disable prettier/prettier */
import styled from 'styled-components';

interface IContainer {
  margin?: string;
}

export const Container = styled.div<IContainer>`
  margin: ${(props) => props.margin ? `${props.margin}` : '0'};

  span { 
    margin-left: 10px
  }

  input {
    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px #48A3F2;
        border: 1px solid #48A3F2;
    }
  }
`;
