import { useQuery } from 'react-query';

import apiIbge from 'services/apiIbge';
import apiCep from 'services/apiCep';

import { ICep, IStates } from './types';

export const useGetStates = () => {
  const getStates = useQuery('states', async () => {
    const { data } = await apiIbge.get<IStates[]>('/estados?orderBy=nome');
    return data;
  });

  return getStates;
};

export const useGetByCep = (cep: string) => {
  const getCep = useQuery(['cep', cep], async () => {
    const { data } = await apiCep.get<ICep>(`${cep}/json`);
    return data;
  });

  return getCep;
};
