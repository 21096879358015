import { Container } from './styles';
import { Simulator } from 'components/Simulator';

export const Simulation = () => {

  return (
    <Container>
      <Simulator />
    </Container>
  );
};
