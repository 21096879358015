import { createContext, useContext, useState } from 'react';
import {
  PromoterContextData,
  PromoterProps,
  PromoterProviderProps,
} from './types';

const UserContext = createContext<PromoterContextData>(
  {} as PromoterContextData
);

export const PromoterProvider = ({ children }: PromoterProviderProps) => {
  const [promoter, setPromoter] = useState<PromoterProps | null>(
    {} as PromoterProps
  );

  return (
    <UserContext.Provider value={{ promoter, setPromoter }}>
      {children}
    </UserContext.Provider>
  );
};

export const usePromoterContext = (): PromoterContextData => {
  const context = useContext(UserContext);

  return context;
};
