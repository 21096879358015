import styled from 'styled-components';

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 30px 0 0 0;
`;


export const Alert = styled.div`
  background-color: #fff3cd;
  background : #fff3cd;
  color: #856404;
  border-radius : 5px;
  padding: 20px;
`;
