
import React from 'react';
import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import SuccessImg from 'assets/images/success.png';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { WizzardSteps } from 'components/WizzardSteps';
import { Flex } from 'components/Flex';
import { ProgressBar } from 'components/ProgressBar';

import { ISimulationData } from 'context/simulation/types';
import { Container, FormFooter, ImgSuccess, TextCenter } from './styles';
import { ISimulationResult } from './types';

export const ProposalResult = ({ setWizzardStep, step }: ISimulationResult) => {
  const { createDigitation, setForm } = useSimulationContext();
  const { promoter } = usePromoterContext();

  const handleFinish = () => {
    setWizzardStep(6);
  };

  const handleNewSimulation = () => {
    setForm({ id: '', cpf: '', phone: '' } as ISimulationData);
    setWizzardStep(1);
  };

  if (createDigitation.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center">
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={35} fontWeight={700}>
              Enviando Proposta
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  if (createDigitation.status === 'error') {
    setWizzardStep(!promoter?.promoterStyles.usePix ? 5 : 9);
  }

  return (
    <Container>
      <WizzardSteps steps={6} step={step} />
      <TextCenter>
        <ImgSuccess src={SuccessImg} alt="bank" />
        <Typography color="success" size={35} fontWeight={700}>
          SUCESSO, SUA SOLICITAÇÃO FOI ENCAMINHADA AO BANCO
        </Typography>
      </TextCenter>
      <FormFooter>
        <a
          style={{ width: '100%' }}
          href={createDigitation.data?.data.urlWhatsappBanco}
          target="_blank"
          rel="noopener noreferrer"
        >
          {
            ( 
              promoter?.promoterStyles.bank === "074" || 
              promoter?.promoterStyles.bank === "389" || 
              promoter?.promoterStyles.bank === "329" || 
              promoter?.promoterStyles.bank === "329-1" || 
              promoter?.promoterStyles.bank === "329-5" || 
              promoter?.promoterStyles.bank === "626"
            ) ? (
              <TextCenter>
                <Typography color="default" size={20} fontWeight={700}>
                  DENTRO DE INSTANTES VOCÊ IRÁ RECEBER UM SMS PARA FINALIZAR SUA SOLICITAÇÃO!
                </Typography>
                <br />
                <Typography color="default" size={14} fontWeight={700}>
                  CASO NÃO RECEBA ENTRE EM CONTATO NO ATENDIMENTO
                </Typography>
              </TextCenter>
            ) : (              
              <Button
                style={{ background: '#17b068' }}
                type="button"
                label="PARA FINALIZAR CLIQUE AQUI PARA CONTINUAR COM A FORMALIZAÇÃO"
                onClick={handleFinish}
              />
            )
          }
        </a>
      </FormFooter>
      <FormFooter>
        <Button
          style={{ background: '#c2c2c2' }}
          type="button"
          label="Nova simulação"
          onClick={handleNewSimulation}
        />
      </FormFooter>
    </Container>
  );
};
