import { Container } from './styles';
import { IButton } from './types';

export const Button = ({id, label, ...rest }: IButton) => {
  return (
    <Container id={id} {...rest}>
      {label}
    </Container>
  );
};
