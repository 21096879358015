export const banks = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '336', label: 'BANCO C6 S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '151', label: 'BANCO NOSSA CAIXA S.A.' },
  { value: '745', label: 'BANCO CITIBANK S.A.' },
  { value: '041', label: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
  { value: '756', label: 'BANCO SICOOB' },
  { value: '748', label: 'BANCO SICREDI' },
  { value: '085', label: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
  { value: '399', label: 'HSBC BANK BRASIL S.A.' },
  { value: '756', label: 'BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.' },
  { value: '041', label: 'BANRISUL' },
  { value: '212', label: 'BANCO ORIGINAL' },
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A' },
  { value: '623', label: 'BANCO PAN S.A.' }
];

export const banksPAN = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '336', label: 'BANCO C6 S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '151', label: 'BANCO NOSSA CAIXA S.A.' },
  { value: '745', label: 'BANCO CITIBANK S.A.' },
  { value: '041', label: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
  { value: '756', label: 'BANCO SICOOB' },
  { value: '748', label: 'BANCO SICREDI' },
  { value: '085', label: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
  { value: '399', label: 'HSBC BANK BRASIL S.A.' },
  { value: '756', label: 'BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.' },
  { value: '041', label: 'BANRISUL' },
  { value: '212', label: 'BANCO ORIGINAL' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A' },  
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' },
  { value: '623', label: 'BANCO PAN S.A.' }
];


export const banksSafra = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '151', label: 'BANCO NOSSA CAIXA S.A.' },
  { value: '745', label: 'BANCO CITIBANK S.A.' },
  { value: '041', label: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.' },
  { value: '756', label: 'BANCO SICOOB' },
  { value: '085', label: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
  { value: '399', label: 'HSBC BANK BRASIL S.A.' },
  { value: '756', label: 'BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.' },
  { value: '041', label: 'BANRISUL' },
  { value: '212', label: 'BANCO ORIGINAL' },
  { value: '422', label: 'BANCO SAFRA' },
  { value: '748', label: 'BANCO COOPERATIVO SICREDI S.A.' },
  { value: '756', label: 'BANCO COOPERATIVO DO BRASIL S/A - BANCOOB/SICOOB' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A' },
  { value: '070', label: 'BRB - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
];


export const banksFacta = [
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '003', label: 'BANCO DA AMAZÔNIA S.A.' },
  { value: '004', label: 'BANCO DO NORDESTE DO BRASIL S.A.' },
  { value: '021', label: 'BANESES S.A.' },
  { value: '024', label: 'BANCO BANDEPE S.A.' },
  { value: '025', label: 'BANCO ALFA S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '037', label: 'BANCO ESTADO DO PARÁ S.A.' },
  { value: '039', label: 'BANCO DO ESTADO DO PIAUI S.A' },
  { value: '041', label: 'BANCO BANRISUL' },
  { value: '047', label: 'BANCO DO ESTADO DE SERGIPE S.A.' },
  { value: '069', label: 'BANCO CREFISA S.A.' },
  { value: '070', label: 'BRB - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.' },
  { value: '073', label: 'BB BANCO POPULAR DO BRASIL S.A.' },
  { value: '074', label: 'BANCO J SAFRA S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '085', label: 'COOPERATIVA CENTRAL DE CRÉDITO URBANO - CECRED' },
  { value: '091', label: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CREDITO MÚTU' },
  { value: '097', label: 'COOPERATIVA CENTRAL DE CRÉDITO NOROESTE BRASILEIRO' },
  { value: '104', label: 'CAIXA ECONÔMICA FEDERAL' },
  { value: '121', label: 'BANCO AGIBANK S.A.' },
  { value: '133', label: 'CONFEDERAÇÃO NACIONAL COOP. CENTRAIS CRÉD. ECON' },
  { value: '136', label: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS' },
  { value: '151', label: 'BANCO NOSSA CAIXA S.A.' },
  { value: '174', label: 'PEFISA SA CREDITO FINANCIAMENTO E INVESTIMENTO' },
  { value: '197', label: 'BANCO STONE PAGAMENTOS S.A.' },
  { value: '208', label: 'BANCO BTG PACTUAL S.A.' },
  { value: '212', label: 'BANCO ORIGINAL S.A.' },
  { value: '218', label: 'BANCO BS2 S.A.' },
  { value: '246', label: 'BANCO ABC BRASIL S.A.' },
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' },
  { value: '318', label: 'BANCO BMG S.A.' },
  { value: '335', label: 'BANCO DIGIO S.A.' },
  { value: '336', label: 'BANCO C6 S.A. (C6 BANK)' },
  { value: '380', label: 'PIC PAY SERVIÇOS S.A.' },
  { value: '382', label: 'BANCO FIDÚCIA' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A' },
  { value: '394', label: 'BANCO BRADESCO FINANCIAMENTOS S.A' },
  { value: '399', label: 'HSBC BANK BRASIL S.A' },
  { value: '403', label: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A' },
  { value: '422', label: 'BANCO SAFRA S.A' },
  { value: '426', label: 'ITI ITAÚ' },
  { value: '473', label: 'BANCO CAIXA GERAL - BRASIL S.A.' },
  { value: '623', label: 'BANCO PAN S.A.' },
  { value: '654', label: 'BANCO A.J. RENNER S.A.' },
  { value: '655', label: 'BANCO VOTORANTIM S.A.' },
  { value: '707', label: 'BANCO DAYCOVAL S.A.' },
  { value: '745', label: 'BANCO CITIBANK S.A.' },
  { value: '748', label: 'BANCO COOPERATIVO SICREDI S.A.' },
  { value: '756', label: 'BANCO COOPERATIVO DO BRASIL S/A - BANCOOB/SICOOB' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' }
];

export const banksMERCANTIL = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '336', label: 'BANCO C6 S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '389', label: 'BANCO MERCANTIL DO BRASIL S.A' },
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' }
];
