import styled from 'styled-components';

interface IContent {
}

export const Content = styled.div<IContent>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  margin: auto;
  padding: 0 20px;
  height: 100%;
`;
