import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';

import { ValidateURL } from 'components/ValidateURL';
import { queryClient } from 'services/queryClient';
import { SimulationProvider } from 'context/simulation/useSimulationContext';
import { PromoterProvider } from 'context/promoter/usePromoterContext';

import { Theme } from 'styles';

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PromoterProvider>
        <Router>
            <SimulationProvider>
              <Theme>
                <ValidateURL />
              </Theme>
            </SimulationProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ fontSize: '13px' }}
              theme="colored"
            />
            {/* <ReactQueryDevtools /> */}
        </Router>
      </PromoterProvider>
    </QueryClientProvider>
  );
};
