import { createContext, useContext, useState } from 'react';
import { formataStringData } from 'utils/masks';
import {
  useCreateSimulation,
  useCreateDigitation,
} from 'hooks/simulation/useSimulationQuery';
import {
  IUseCreateDigitation,
  IUseCreateSimulation,
} from 'hooks/simulation/types';
import {
  ISimulationData,
  SimulationContextData,
  SimulationProviderProps,
} from './types';

const SimulationContext = createContext<SimulationContextData>(
  {} as SimulationContextData
);

export const SimulationProvider = ({ children }: SimulationProviderProps) => {
  const create = useCreateSimulation();
  const createDigitation = useCreateDigitation();
  const [form, setForm] = useState<ISimulationData>({
    id: '',
    cpf: '',
    phone: '',
    birthDate: '',
    name: '',
    indication: '',
    pix: ''
  } as ISimulationData);

  const handleSimulate = async (data: IUseCreateSimulation) => {

    const formatedData = {
      id: data.id,
      name : data.name,
      birthDate : formataStringData(data.birthDate.replace(/[/]/g,'-')),
      cpf: data.cpf.split(/[ .-]+/).join(''),
      phone: data.phone.split(/[ )(-]+/).join(''),
      token : data.token,
      indication: data.indication
    };
    setForm(data);
    create.mutateAsync(formatedData);
  };

  const handleSubmitDigitation = async (data: IUseCreateDigitation) => {
    
    const valueSolicited = data?.valueSolicited;

    // const valueIncome = String(
    //   Number(data?.valueIncome?.replace(/\D/g, '')) / 100
    // );

    const formatedData = {
      ...data,
      valueSolicited,
      valueIncome : '2500.00',
    };
    createDigitation.mutateAsync(formatedData);
  };

  return (
    <SimulationContext.Provider
      value={{
        form,
        setForm,
        handleSimulate,
        create,
        handleSubmitDigitation,
        createDigitation,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
};

export const useSimulationContext = (): SimulationContextData => {
  const context = useContext(SimulationContext);

  return context;
};
