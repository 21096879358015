import { Container } from './styles';
import { IFlex } from './types';

export const Flex = ({
  style,
  direction,
  alignItems,
  justifyContent,
  padding,
  margin,
  width,
  height,
  flexBasis,
  children,
  flexWrap,
  noWrap,
  onClick,
  ...rest
}: IFlex) => (
  <Container
    style={style}
    direction={direction}
    alignItems={alignItems}
    justifyContent={justifyContent}
    padding={padding}
    margin={margin}
    width={width}
    height={height}
    onClick={onClick}
    flexBasis={flexBasis}
    flexWrap={flexWrap}
    noWrap={noWrap}
    {...rest}
  >
    {children}
  </Container>
);
