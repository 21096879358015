import { ReactNode } from 'react';
import { TransparentBackground, Content } from './styles';

interface ModalProps {
  isOpen: boolean;
  desktop?: boolean;
  children: ReactNode;
  onCancel: () => void;
}

export const Modal = ({ onCancel, isOpen, children, desktop }: ModalProps) => (
  <>
    <TransparentBackground onClick={onCancel} isOpen={isOpen} />
    <Content desktop={desktop} isOpen={isOpen}>
      {children}
    </Content>
  </>
);
