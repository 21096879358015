import styled from 'styled-components';

interface ContentProps {
  isOpen: boolean;
  desktop?: boolean;
}

export const TransparentBackground = styled.div<ContentProps>`
  position: fixed;
  background: rgba(235, 238, 243, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
`;

export const Content = styled.div<ContentProps>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  background-color: #fefefe;
  box-shadow: 0 0 6px 1px rgb(0 0 0 / 16%);
  border-radius: 3px;
  padding: 20px;
  margin-top: 20px;
  width: 85%;
  max-width: ${(props) => (props.desktop ? '1160px' : '450px')};
  max-height: 85%;
  margin: auto;
  overflow: auto;
`;
