import { useQuery } from 'react-query';
import api from 'services/api';

import { PromoterProps } from './types';

export const useGetPromoter = (pathname: string, indication ?: string) => {
  const getPromoter = useQuery(['promoter', pathname], async () => {
    const { data } = await api.get<PromoterProps>(
      `/v1/promoter-styles${pathname}`, {params: {indication : indication}}
    );
    return data;
  },
  { refetchOnWindowFocus: false, enabled: false });

  return getPromoter;
};
