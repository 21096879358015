/* eslint-disable */
import { useEffect, useState } from 'react';
// import BankAccountValidator from 'br-bank-account-validator';

import WarningDanger from 'assets/icons/warning-danger.svg';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
// import { ISimulationData } from 'context/simulation/types';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { ButtonBack } from 'components/Button/styles';
import {cpf} from 'cpf-cnpj-validator';

import { Alert, FormFooter } from './styles';
import { ISimulationForm } from './types';
import Select from 'components/Form/Select';
import { cpfMask, phoneMask } from 'utils/masks';
import Swal from 'sweetalert2';

export const BankDataPix = ({ setWizzardStep, step }: ISimulationForm) => {
  const { form, setForm, handleSubmitDigitation } = useSimulationContext();
  const [isOpen, setIsOpen] = useState(false);
  const [typePix, setTypePix] = useState('');
  const [bank, setBank] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let value = event.target.value

    // if(typePix === '1'){
    //   value = cpfMask(value);
    // }

    // if(typePix === '2'){
    //   value = phoneMask(value);
    // }

    setForm({
      ...form,
      [event.target.name]: value,
    });

    // && typePix && bank
    if(form.pix){
      setBtnDisabled(false)
    }

  };

  const handleBackWizzard = () => {
    setWizzardStep(4);
  }

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    try {

      // if(typePix === '1'){
      //   if (!cpf.isValid(form.pix ?? '')) {
      //     Swal.fire({
      //       icon: 'error',
      //       title: 'Erro',
      //       text: 'O CPF informado é inválido'
      //     })
      //     return;
      //   }
      // }
      
      let  pix = form.pix;

      // if(pix){
      //   if(typePix === '1' || typePix === '2'){
      //     pix = pix.replace(/[^0-9]/g, '').replace('+55', '');
      //   }

      //   if(typePix === '2'){  
      //     pix = '+55' + pix
      //   }
      // }

      setForm({
        ...form,
        pix: pix?.toLowerCase()
      });

      handleSubmitDigitation(form);
      
      setWizzardStep(6);
    } catch (e) {
      setForm({
        ...form,
        pix: ''
      });
      setIsOpen(true);
    }

  };
  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={35} fontWeight={700}>
          Dados Bancários
        </Typography>
        <Typography color="default" size={15}>
          Informe seus dados bancários para continuar
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
      {/* <Select
          id="typePix"
          name="typePix"
          label="Bancos disponíveis"
          value={bank}
          onChange={(ev) => {
            setBank(ev.target.value);
            
            setForm({
              ...form,
              pix: ''
            });
          }}
          required
          options={[
            { value: '1', label: 'BANCO DO BRASIL' },
            { value: '2', label: 'CAIXA ECONOMICA FEDERAL' },
            { value: '3', label: 'BANCO ITAÚ' },
            { value: '4', label: 'BANCO BRADESCO' },
            { value: '5', label: 'BANCO SANTANDER' },
            { value: '6', label: 'BANCO BANRISUL' },
            { value: '7', label: 'BANCO BRB - BANCO DE BRASÍLIA S.A' },
            { value: '8', label: 'BANCO ALFA' },
            { value: '9', label: 'BANCO DA AMAZÔNIA' },
            { value: '10', label: 'BANCO BANPARA' },
            { value: '11', label: 'BANCO BANESE' },
            { value: '12', label: 'BANCO MERCANTIL DO BRASIL' },
            { value: '13', label: 'BANCO BANESTES' },
            { value: '14', label: 'BANCO SICREDI' },
            { value: '15', label: 'BANCO SICOOB' },
            { value: '16', label: 'BANCO ASCOOB' },
            { value: '17', label: 'BANCO UNICRED' },
            { value: '18', label: 'BANCO CRESOL' },
            { value: '19', label: 'BANCO CCLA' },
            { value: '20', label: 'BANCO UNIPRIME' },
            { value: '21', label: 'BANCO INTER' },
            { value: '22', label: 'NUBANK' },
            { value: '23', label: 'NEXT' },
            { value: '24', label: 'BANCO BTG PACTUAL' },
            { value: '25', label: 'BANCO MODALMAIS' },
          ]}
        />
        <Select
            id="typePix"
            name="typePix"
            label="Tipo de chave PIX"
            value={typePix}
            onChange={(e) => {
              if(e.target.name === 'typePix'){
                setTypePix(e.target.value);
            
                setForm({
                  ...form,
                  pix: ''
                });
              }
            }}
            required
            options={[
              { value: '1', label: 'CPF' },
              { value: '2', label: 'Celular' },
              { value: '3', label: 'E-mail' },
              { value: '4', label: 'Aleatória' },
            ]}
          /> */}
        <Input
          isFullWidth
          id="pix"
          name="pix"
          label="PIX"
          placeholder='Informe seu PIX aqui, E-mail, Celular, CPF ou Chave aleatória'
          required
          value={form.pix}
          onChange={handleChange}
          onBlur={handleChange}
          onFocus={handleChange}
        />
        <FormFooter>
          <Button id='btn-finalizar' label="Finalizar" type="submit" disabled={btnDisabled} />
        </FormFooter>
      </Form>

      <ButtonBack onClick={handleBackWizzard} className="btn btn-success btn-md">Voltar</ButtonBack>

      <Modal isOpen={isOpen} onCancel={() => setIsOpen(false)}>
        <Flex direction="column" width="100%">
          <img
            src={WarningDanger}
            alt="Aviso!"
            style={{ width: '78px', paddingBottom: '20px', margin: 'auto' }}
          />
          <Flex direction="column" width="100%">
            <Flex
              margin="0 0 20px 0"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="danger" size={16}>
                Atenção, dados bancários incorretos
              </Typography>
            </Flex>
            <Button label="Ok" type="button" onClick={() => setIsOpen(false)} />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
