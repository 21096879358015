import { createGlobalStyle } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { useCustomerTheme } from './useCustomerTheme';

import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = () => {
  const { pathname } = useLocation();
  const defaultTheme = useCustomerTheme();

  const pathArray = pathname.split('/');

  const GlobalStyleTheme = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
  }

  *:focus {
    outline: 0;
  }

  html {
    background: ${pathArray[2] !== 'minimal' ? `linear-gradient(180deg, ${defaultTheme.bgColorPrimary}, ${defaultTheme.bgColorSecondary})` : null};
    /* : ; */
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  #root {
    height: 100%;
  }

  body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  }

  `;

  return GlobalStyleTheme;
};
