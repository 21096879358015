import { Content } from './styles';
import { IContainer } from './types';

export const Container = ({ children, ...rest }: IContainer) => {
  return (
    <Content
      {...rest}
    >
      {children}
    </Content>
  );
};
