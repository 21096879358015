import { useState } from 'react';

import { Card } from 'components/Card';

import { SimulationForm } from './Wizzard/SimulationForm';
import { SimulationResult } from './Wizzard/SimulationResult';
import { PersonalData } from './Wizzard/ProposalForm/PersonalData';
import { AddressData } from './Wizzard/ProposalForm/AddressData';
import { BankData } from './Wizzard/ProposalForm/BankData';
import { ProposalResult } from './Wizzard/ProposalResult';
import { QuestionFgts1 } from './Wizzard/QuestionFgts1';
import { QuestionFgts2 } from './Wizzard/QuestionFgts2';
import { BankDataPix } from './Wizzard/ProposalForm/BankDataPix';

export const Simulator = () => {
  const [wizzardStep, setWizzardStep] = useState(1);

  const wizzard = () => {
    switch (wizzardStep) {
      case 1:
        return (
          <SimulationForm setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 2:
        return (
          <SimulationResult setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 7:
        return (
          <QuestionFgts1 setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 8:
        return (
          <QuestionFgts2 setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 3:
        return (
          <PersonalData setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 4:
        return (
          <AddressData setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 5:
        return <BankData setWizzardStep={setWizzardStep} step={wizzardStep} />;
      case 9:
        return <BankDataPix setWizzardStep={setWizzardStep} step={wizzardStep} />;
      case 6:
        return (
          <ProposalResult setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      default:
        break;
    }
  };

  return (
      <Card>{wizzard()}</Card>
  );
};
