import styled from 'styled-components';

interface IStepsIndicator {
  done: boolean;
}

export const Container = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  margin: 0 0 20px 0;
`;

export const StepsIndicator = styled.div<IStepsIndicator>`
  background: ${(props) =>
  props.done ? props.theme.colors.primary : '#e6e6e6'};
  width: 50px;
  height: 50px;
  padding: 13px;
  border-radius: 30px;
  text-align: center;
  font-weight: bold;
  color: ${(props) =>
    props.done ? props.theme.colors.white : '#333333'};
  margin-right: 10px;
  margin-bottom: 10px;

  @media only screen and (max-width: 500px) {
  width: 35px;
  height: 35px;
  padding: 6px;
  }
`;
