import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const BankLogo = styled.img`
  width: 250px;
  margin-bottom: 10px;
`;

export const Conditions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConditionItem = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 30px 0 0 0;
`;

export const Row = styled.div`  
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 40px;
  margin-top: 40px;
  border-top: 2px solid #f3f3f3;

  @media (max-width: 500px) {
    display: block;
    align-items: unset;
    text-align: center;
  }
`;

export const Col = styled.div`
  display: inline-block;
  width: 65%;
  @media (max-width: 500px) {
    margin-top: 20px;
    width: 100% !important;
    text-align: center;
  }
  
  &:first-child{
    width: 35%;
    @media (max-width: 500px) {
      width: 100% !important;
      text-align: center;
    }
  }
`;

export const Title = styled.h1`
  font-size: 23px;
  color: #000000;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #000000;
  margin-top: 5px;
`;

export const TitlePrimary = styled.h1`
  font-size: 28px;
  color: #15CD72;
`;

export const AlignImg = styled.div`
  text-align: center;
`;

export const ImgFgts = styled.img`
  width: 180px;
  border-radius: 5px;
  box-shadow: 0 0 6px #dfdfdf;
`;


export const A = styled.a`
`;


export const ContainerCaptcha = styled.div`
  padding-top: 20px
`;
