import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const BankLogo = styled.img`
  width: 250px;
  margin-bottom: 10px;
`;

export const TextCenter = styled.div`
  text-align: center;
`

export const ImgSuccess = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`;

export const Conditions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConditionItem = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 30px 0 0 0;
`;
