export const currencyFormat = (value: any) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));

export const USDcurrencyFormat = (value: any) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
